import { Calendar, Input, Tabs, YYYY_MM_DD_format } from '@mgk-eld/core';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { getDate } from '../../redux/simple/selectors';
import { setDate } from '../../redux/simple/slice';
import DelayedEvents from './DelayedEvents';
import DriverForms from './DriverForms';
import Udps from './Udps';
import Violations from './Violations';
import Warnings from './Warnings';
import styled from '@emotion/styled';
import { Grid, ListItemText } from '@mui/material';
import { useMemo, useState } from 'react';
import { SearchIcon } from '@mgk-eld/assets';
import { dayjs } from '@mgk-eld/utils';

export const Title = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.palette.primary.main};
    text-transform: uppercase;
    font-size: ${(props) => props.theme.typography.body1.title.fontSize}px;
    font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  }
`;

export enum TabsEnum {
  Violations,
  Forms,
  Uies,
  Warnings,
}

export const initialState = { data: [], total: 0, loading: false };

const Errors = () => {
  const dispatch = useAppDispatch();
  const [driverName, setDriverName] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentTab, setCurrentTab] = useState<TabsEnum>(TabsEnum.Violations);
  const date = useAppSelector(getDate);
  const formattedDate = useMemo(
    () => dayjs(date).format(YYYY_MM_DD_format),
    [date]
  );

  const handleCalendarChange = (value: string) => {
    dispatch(setDate(value));
  };

  const handleSearchChange = (value: string) => {
    setDriverName(value);
  };
  return (
    <Grid container direction="column" paddingX={2} paddingY={4}>
      <Grid container direction="row">
        <Grid item md={3}>
          <Title>Errors</Title>
        </Grid>
        <Grid item md={6} textAlign="center">
          <Input
            initialValue=""
            handleChange={handleSearchChange}
            placeholder="Search by name"
            Icon={SearchIcon}
          />
        </Grid>
        <Grid
          item
          md={3}
          textAlign="end"
          display="flex"
          justifyContent="flex-end"
        >
          <Calendar date={date} handleChange={handleCalendarChange} />
        </Grid>
      </Grid>
      <Grid container mt={'10px'}>
        <Tabs<TabsEnum>
          setCurrentTab={setCurrentTab}
          tabs={[
            'Violations',
            'Forms',
            'Delayed events',
            'Unidentified driver profile',
            'Warnings',
          ]}
          tabpanels={[
            <Violations driverName={driverName} date={formattedDate} />,
            <DriverForms driverName={driverName} date={formattedDate} />,
            <DelayedEvents driverName={driverName} date={formattedDate} />,
            <Udps date={formattedDate} />,
            <Warnings driverName={driverName} date={formattedDate} />,
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Errors;
