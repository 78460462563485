import styled from '@emotion/styled';
import { blue, grey } from '@mgk-eld/utils';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';

export const CustomActionButton = styled(Button)<{ background?: string }>`
  width: 127px;
  height: 40px;
  border-radius: 8px;
  &:hover {
    background: ${(props) => (props.background ? props.background : grey[600])};
  }
`;

export const CustomButton = styled(Button)<{
  selected?: boolean;
  width?: number;
}>`
  width: ${({ width }) => width ?? 94}px;
  height: 32px;
  border-radius: 8px;
  border: ${(props) => (props.selected ? '1.5px solid #E6EFF7' : 'none')};
  &:hover {
    background: ${(props) => (props.selected ? blue[250] : blue[150])};
    border: ${(props) =>
      props.selected ? `1.5px solid ${blue[100]}` : 'none'};
    color: white;
  }
  background: ${(props) =>
    props.disabled
      ? grey[300]
      : props.selected
      ? 'white'
      : props.style?.backgroundColor ?? blue[500]};
  color: ${(props) => (props.selected ? [grey[1000]] : 'white')};
`;

export const ReportButton = styled(LoadingButton)`
  border-radius: 8px;
  padding: 11px 40px;
  background-color: #085dad;
  &:hover {
    background: #075095;
  }
`;

export const LogsReportButton = styled(LoadingButton)`
  border-radius: 8px;
  padding: 8px 40px;
  background-color: #085dad;
  &:hover {
    background: #075095;
  }
`;
