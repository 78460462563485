import { CustomPaper, Input } from '@mgk-eld/core';
import { StyledPopper } from '../../components/navbar/NavbarSelect';
import { USER_ROLES } from '../../constants/users';
import { useAppSelector } from '../../hooks/reduxHooks';
import { getSelectedCompanyId } from '../../redux/companies/selectors';
import styled from '@emotion/styled';
import { Autocomplete, TextField, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

export enum Types {
  Vehicles,
  CoDrivers,
}

const StyledAutoCompletePopper = styled(StyledPopper)(() => ({
  height: 'auto',
}));

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export type Option = { label: string; value: number };

const EditComponent: React.FC<{
  value: string | undefined;
  setValue: (input: string | Option) => any;
  getOptions?: (params?: any) => Promise<any>;
  id: number;
  searchField?: string;
  type?: Types;
  validations?: any;
}> = ({
  value = '',
  setValue,
  getOptions,
  searchField,
  type,
  validations,
  id,
}) => {
  const selectedCompany = useAppSelector(getSelectedCompanyId);
  const [isOpen, setOpen] = useState<boolean>(true);

  const [isEditing, setIsEditing] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');

  const handleChange = useCallback(
    (val: string | Option) => {
      setIsEditing(false);
      setValue(val);
      setOpen(false);
    },
    [setValue]
  );

  const loadOptions = useCallback(async () => {
    if (!selectedCompany || !getOptions || !searchField || !isEditing) return;
    setLoading(true);
    const {
      data: { items },
    } = await getOptions({
      page: 1,
      size: 100,
      company_id: selectedCompany,
      [searchField]: inputValue.split(' ').slice(0, 2).join(' '),
      roles: [USER_ROLES.driver.code],
    });

    if (type === Types.CoDrivers) {
      items.unshift({ driver_id: null, name: 'No', surname: 'Option' });
    }

    setOptions(
      items
        .filter((item: any) => item.driver_id !== id)
        .map((item: any) => ({
          value: type === Types.CoDrivers ? item.driver_id : item.id,
          label:
            type === Types.Vehicles
              ? item.unit_number
              : `${item.name} ${item.surname} ${
                  item.username ? `(${item.username})` : ''
                }`,
        }))
    );
    setLoading(false);
  }, [
    selectedCompany,
    getOptions,
    searchField,
    isEditing,
    inputValue,
    type,
    id,
  ]);

  useEffect(() => {
    if (loadOptions) loadOptions();
  }, [loadOptions]);

  const handleBlur = useCallback(() => {
    setIsEditing(false);
    setOpen(false);
  }, []);

  const stopPropagation = useCallback((e: any) => {
    e.stopPropagation();
    setOpen((prev) => !prev);
  }, []);

  const getEditComponent = useCallback(() => {
    if (!getOptions) {
      return (
        <Input
          validations={validations}
          onClick={stopPropagation}
          onBlur={handleBlur}
          autoFocus
          placeholder=""
          initialValue={value ?? ''}
          handleSubmit={handleChange}
        />
      );
    }

    return (
      <Autocomplete
        id="autocomplete"
        PopperComponent={StyledAutoCompletePopper}
        noOptionsText="No Option"
        fullWidth
        inputValue={inputValue}
        onInputChange={(e, v) => setInputValue(v)}
        onChange={(e, opt) => handleChange(opt ?? '')}
        filterOptions={(x) => x}
        open={isOpen}
        value={options.find((o) => o.label === value)}
        isOptionEqualToValue={(option, v) => option.value === v.value}
        getOptionLabel={(option) => option.label}
        options={options}
        loading={loading}
        clearIcon={null}
        renderInput={(params) => (
          <CustomPaper
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: 40,
            }}
          >
            <TextField
              autoFocus
              onBlur={handleBlur}
              onClick={stopPropagation}
              {...params}
              sx={{
                '& fieldset': { border: 'none' },
              }}
            />
          </CustomPaper>
        )}
      />
    );
  }, [
    getOptions,
    inputValue,
    isOpen,
    options,
    loading,
    validations,
    stopPropagation,
    handleBlur,
    value,
    handleChange,
  ]);

  return (
    <Container
      onKeyDown={(e) => e.stopPropagation()}
      onClick={() => setIsEditing((curr) => !curr)}
    >
      {!isEditing ? (
        <Tooltip placement="top" title={value}>
          <div>{value}</div>
        </Tooltip>
      ) : (
        getEditComponent()
      )}
    </Container>
  );
};

export default EditComponent;
