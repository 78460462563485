import { useMediaQuery } from '@mui/material';
import { AbilityProvider } from './contexts/AbilityContext';
import { AuthProvider } from './contexts/JWTContext';
import ErrorBoundary from './layout/ErrorBoundary';
import { store } from './redux/store';
import routes from './routes';
import { CacheProvider } from '@emotion/react';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createEmotionCache, createTheme } from '@mgk-eld/utils';

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);
  const theme = createTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <CacheProvider value={emotionCache}>
      <ToastContainer position={isDesktop ? 'top-right' : 'bottom-right'} />
      <HelmetProvider>
        <Helmet
          titleTemplate="%s | MGK ELD User portal"
          defaultTitle="MGK ELD User portal"
        />
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <AuthProvider>
              <AbilityProvider>
                <ErrorBoundary>{content}</ErrorBoundary>
              </AbilityProvider>
            </AuthProvider>
          </MuiThemeProvider>
        </Provider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
