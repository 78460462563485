import { CopyIcon } from '@mgk-eld/assets';
import { baseUrl } from '@mgk-eld/utils';
import { Box, IconButton, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';

export const handleCopy = (copyText: string) => {
  navigator.clipboard.writeText(copyText);
  toast.info('Copied to clipboard');
};

const TrackingLink: React.FC<{ token: string }> = ({ token }) => {
  const link = useMemo(
    () => `${baseUrl.trackingApp}dashboard?access_token=${token}`,
    [token]
  );
  return (
    <Box>
      <Box display={{ xs: 'none', md: 'inline' }} component="span">
        <a href={link} style={{ textDecoration: 'none' }}>
          https://track.mgkeld....
        </a>
      </Box>
      <Tooltip title="Copy" placement="top">
        <IconButton
          onClick={() =>
            handleCopy(`${baseUrl.trackingApp}dashboard?access_token=${token}`)
          }
          color="info"
        >
          <CopyIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default TrackingLink;
