import {
  PageOptions,
  axiosInstance,
  baseUrl,
  exportExcel,
} from '@mgk-eld/utils';

const endPoint = `${baseUrl.https}companies`;

interface GetCompanyProps extends PageOptions {
  name?: string | undefined;
  include_deleted?: boolean;
}

const getCompanies = (params: GetCompanyProps) => {
  return axiosInstance.get(`${endPoint}`, { params });
};

const createCompany = (body: any) => axiosInstance.post(`${endPoint}`, body);

const deactivateCompany = (id: number | string, deleted: boolean) => {
  return axiosInstance.patch(`${endPoint}/${id}`, { deleted });
};

const getCompanyById = (id: number | string) =>
  axiosInstance.get(`${endPoint}/${id}`);

const editCompanyById = (data: unknown, id: number | string) =>
  axiosInstance.put(`${endPoint}/${id}`, data);

const downloadXLSX = async () => {
  exportExcel(
    `${endPoint}/download/list`,
    undefined,
    'companies.xlsx',
    axiosInstance.defaults.headers.common
  );
};

const autoFill = (dot_number: string) =>
  axiosInstance.get(`${baseUrl.https}companies/info/autofill`, {
    params: { dot_number },
  });

const getApiKey = (company_id: number) =>
  axiosInstance.get(`${baseUrl.https}companies/${company_id}/api-keys`);

const createApiKey = (company_id: number) =>
  axiosInstance.post(`${baseUrl.https}companies/${company_id}/api-keys`);

const deleteApiKey = (company_id: number, id: number) =>
  axiosInstance.delete(
    `${baseUrl.https}companies/${company_id}/api-keys/${id}`
  );

const companiesApi = {
  getCompanies,
  deactivateCompany,
  getCompanyById,
  editCompanyById,
  createCompany,
  downloadXLSX,
  autoFill,
  getApiKey,
  createApiKey,
  deleteApiKey,
};
export default companiesApi;
