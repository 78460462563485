import {
  axiosInstance,
  baseUrl,
  exportExcel,
  extendedIftaReportValidationSchema,
} from '@mgk-eld/utils';
import { ReportRequestParams } from '../../../services/reports';
import { Option, ReportItem } from '../types';
import { ReportsItem } from './ReportsItem';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const ExtendedIFTA: React.FC<{ vehicles: Option[] }> = ({ vehicles }) => {
  const [loadingXls, setLoadingXls] = useState(false);

  const onSubmit = useCallback(async (params: ReportRequestParams) => {
    const loadFunc = setLoadingXls;
    loadFunc(true);
    delete params.type;
    const newParams = params;
    try {
      await exportExcel(
        `${baseUrl.https}data-points/download/xlsx`,
        newParams,
        'extendedIFTA.xlsx',
        axiosInstance.defaults.headers.common
      );
    } catch (error: any) {
      toast.error(error.response.data.error);
    }
    loadFunc(false);
  }, []);

  const ExtendedIFTA = useMemo<ReportItem>(
    () => ({
      onSubmit,
      title: 'Extended IFTA',
      url: 'data-points/download/xlsx',
      selects: [
        {
          id: 'vehicle_id',
          title: 'Vehicle',
          isMulti: false,
          items: vehicles,
        },
      ],
      inputs: [],
      validationSchema: extendedIftaReportValidationSchema,
      submitButtons: [
        {
          id: 'xlsx',
          name: 'Generate XLS',
          loading: loadingXls,
        },
      ],
    }),
    [loadingXls, onSubmit, vehicles]
  );
  return <ReportsItem report={ExtendedIFTA} />;
};

export default ExtendedIFTA;
