import breakpoints from './breakpoints';
import components from './components';
import shadows from './shadows';
import typography from './typography';
import variants from './variants';
import createMuiTheme from '@mui/material/styles/createTheme';

export const createTheme = () => {
  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-expect-error "not strictly compatible"
      components: components,
      typography: typography,
      shadows: shadows,
      palette: variants.palette,
    },
    {
      name: variants.name,
      header: variants.header,
      sidebar: variants.sidebar,
    }
  );
};

export * from './colors';
