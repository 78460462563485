import { fmcsaReportValidationSchema } from '@mgk-eld/utils';
import reportsApi, { ReportRequestParams } from '../../../services/reports';
import { Option, ReportItem } from '../types';
import { ReportsItem } from './ReportsItem';
import React, { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

const webService = [
  {
    title: 'Web service',
    value: 'webservice',
  },
  {
    title: 'Email',
    value: 'email',
  },
];

const FMCSAReport: React.FC<{
  drivers: Option[];
}> = ({ drivers }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async (params: ReportRequestParams) => {
    setLoading(true);
    try {
      const { status } = await reportsApi.report('eld-output-file/send', {
        ...params,
        show_title: true,
      });
      if (status === 200) toast.success('Success');
    } catch (error: any) {
      toast.error(error.response.data.error);
    }
    setLoading(false);
  }, []);
  const FMCSAReport = useMemo<ReportItem>(
    () => ({
      onSubmit,
      title: 'FMCSA Report',
      validationSchema: fmcsaReportValidationSchema,
      selects: [
        {
          id: 'driver_id',
          title: 'Driver',
          isMulti: false,
          items: drivers,
        },
        {
          id: 'type',
          title: 'Web service',
          isMulti: false,
          items: webService,
        },
      ],
      inputs: [
        {
          id: 'output_file_comment',
          title: 'Notes',
        },
      ],
      submitButtons: [
        {
          name: 'Send Logs',
          loading,
        },
      ],
    }),
    [drivers, loading, onSubmit]
  );

  return <ReportsItem report={FMCSAReport} />;
};

export default FMCSAReport;
